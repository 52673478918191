// Next.js code is moved here because this is included in xcover-www-frontend bundle when '@whitelabel/helpers/hooks' is imported for some reason. Looks like tree shaking is not working properly
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { BRIGHTWRITE_APP_ID, BRIGHTWRITE_APP_VERSION, BRIGHTWRITE_PUBLIC_KEY } from '@whitelabel/helpers/api';

export const useNextPageViews = (): void => {
  const { pathname } = useRouter();
  useEffect(() => {
    if (window.isHeadless) {
      return;
    }
    window.bwtag('set', 'appId', BRIGHTWRITE_APP_ID);
    window.bwtag('set', 'appVersion', BRIGHTWRITE_APP_VERSION);
    window.bwtag('set', 'publicKey', BRIGHTWRITE_PUBLIC_KEY);
  }, []);
  useEffect(() => {
    if (window.isHeadless) {
      return;
    }
    window.bwtag('pv', { server: 'next.js' });
  }, [pathname]);
};
