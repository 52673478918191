import { useMemo } from 'react';
import * as Sentry from '@sentry/nextjs';
import createSentryMiddleware from 'redux-sentry-middleware';
import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import { createRouterMiddleware } from 'connected-next-router';
import { isOnServer } from '@whitelabel/helpers/utils';
import { rootSaga } from '@whitelabel/xcover-www-frontend/src/actions';
import { xcoverAPI } from '@whitelabel/xcover-shared/store/services/xcover/index';
import { rtkQueryErrorLogger } from '@whitelabel/xcover-shared/store/middlewares/ApiErrorLogger';
import rootReducer from './reducers';
import { IInitialStoreState } from './helpers/types';

export const sagaMiddleware = createSagaMiddleware();

let store: any;

const makeStore = (preloadedState: any) => {
  const nextJsAppStore = configureStore({
    reducer: {
      ...rootReducer,
      [xcoverAPI.reducerPath]: xcoverAPI.reducer,
    },
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(createRouterMiddleware())
        .concat(sagaMiddleware)
        .concat(createSentryMiddleware(Sentry, {}))
        .concat(xcoverAPI.middleware)
        .concat(rtkQueryErrorLogger),
  });

  sagaMiddleware.run(rootSaga);

  return nextJsAppStore;
};

export const initializeStore = (preloadedState: IInitialStoreState) => {
  let reduxStore = store ?? makeStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    reduxStore = makeStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (isOnServer) return reduxStore;
  // Create the store once in the client
  if (!store) store = reduxStore;

  return reduxStore;
};

export function useStore(initialState: IInitialStoreState) {
  const reduxStore = useMemo(() => initializeStore(initialState), [initialState]);
  return reduxStore;
}
